import {BrowserRouter as Router , Routes, Route} from 'react-router-dom'
import Home from './pages/Home';
import Footer from './components/Footer';
import Header from './components/Header';
import About from './pages/About'; 
import Contact from './pages/Contact';
import ProductPage from './pages/ProductPage';
import SingleCategoryPage from './pages/SingleCategoryPage';

function App() {
  return (
     <div className='main-body'>
      <Router>
        <Header/>

        <Routes>
          <Route path='/' element={<Home />}/>
          <Route path='/about' element={<About />}/>
          <Route path='/products' element={<ProductPage />}/>
          <Route path='/products' elemett={<ProductPage/>}/>
          <Route path='/contact' element={<Contact />}/>
          <Route path='/product-view/:category' element={<SingleCategoryPage/>}/>
        </Routes>
        <Footer/>
      </Router>
     </div>
  );
}

export default App;
