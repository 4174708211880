import React from 'react'

function Footer() {
  return (
    <> 
<div className="container-fluid bg-light mt-5 py-5">
  <div className="container pt-5">
    <div className="row g-5">
    <div className="col-lg-4 col-md-6">
        <h5 className="text-uppercase border-start border-5 border-primary ps-3 mb-4">Get In Touch</h5>
        <h6 className="text-primary">MEHRA APPAREL</h6> 
        <p className="mb-2"><i className="bi bi-envelope-open text-primary me-2"></i>sanyam@mehragroup.co.in</p>
        <p className="mb-0"><i className="bi bi-telephone text-primary me-2"></i>+91 9794000003</p>

        <h6 className="text-primary">EQUINE MAKERS</h6> 
        <p className="mb-2"><i className="bi bi-envelope-open text-primary me-2"></i>info@mehragroup.co.in</p>
        <p className="mb-0"><i className="bi bi-telephone text-primary me-2"></i>+91 9839034846</p>
        <h6 className="text-primary">MEHRA SCHUHE</h6> 
        <p className="mb-2"><i className="bi bi-envelope-open text-primary me-2"></i>dir.mehrashoes@gmail.com</p>
        <p className="mb-0"><i className="bi bi-telephone text-primary me-2"></i>+91 9643600007</p>
      </div>

      <div className="col-lg-8 col-md-6">
        <h5 className="text-uppercase border-start border-5 border-primary ps-3 mb-4">ABOUT MEHRA GROUP</h5>
        <div className="row">
          <div className="col-sm-3">
            <img src="img/logo-footer.png" style={{height:"60px"}} />
          </div>
          <div className="col-sm-9">
            <p>Mehra Apparel is involved in the production of Garments, specially breeches.</p>
          </div>

        </div>
        <div className="row">
          <div className="col-sm-3">
            <img src="img/logo-footer1.png" style={{height:"60px"}} />
          </div>
          <div className="col-sm-9">
            <p>Equine Makers is involved in leather products.</p>
          </div>

        </div>
        <div className="row">
          <div className="col-sm-3">
            <img src="img/logo-footer2.png" style={{height:"60px"}} />
          </div>
          <div className="col-sm-9">
            <p>Mehra Schuhe manufactures riding boots, fashion sandals and Shoes</p>
          </div>

        </div>
         
      </div>

    </div>
  </div>
</div>
<div className="container-fluid bg-dark text-white-50 py-4">
  <div className="container">
    <div className="row g-5">
      <div className="col-md-6 text-center text-md-start">
        <p className="mb-md-0">&copy; <a className="text-white" href="#">Mehra Group</a>. All Rights Reserved.</p>
      </div>
      <div className="col-md-6 text-center text-md-end">
        <p className="mb-0"> Developed by :-<a className="text-white" href="https://acetians.com/">Acetians Technologies Pvt.
            Ltd.</a></p>
      </div>
    </div>
  </div>
</div>
<a href="#" className="btn btn-primary py-3 fs-4 back-to-top"><i className="bi bi-arrow-up"></i></a>

    </>
  )
}

export default Footer