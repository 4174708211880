const initialState={
    products:[],
    isAuthenticated:false,
    isLoading:true
}


const rootReducer = (state= initialState,action)=>{
    switch (action.type){
        case 'PRODUCTS_LOADED':
            return {...state,isLoading:false}
        default:
            return state
    }        
}

export default rootReducer;
