import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';

function Header() {
  const toggleRef = useRef(null);

  const handleClicked = () => {
    if (toggleRef.current) {
      toggleRef.current.click();
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });  
  };

  return (
    <>
      <div className="container-fluid border-bottom d-none d-lg-block">
        <div className="row gx-0">
          <div className="col-lg-4 text-center py-2">
            <div className="d-inline-flex align-items-center">
              <i className="bi bi-geo-alt fs-1 text-primary me-3"></i>
              <div className="text-start">
                <h6 className="text-uppercase mb-1">Address</h6>
                <span>123/768-76-A8 Fazalganj, Factory Area Kanpur, Uttar Pradesh, India</span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 text-center border-start border-end py-2">
            <div className="d-inline-flex align-items-center">
              <i className="bi bi-envelope-open fs-1 text-primary me-3"></i>
              <div className="text-start">
                <h6 className="text-uppercase mb-1">Email Us</h6>
                <span>info@mehragroup.co.in</span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 text-center py-2">
            <div className="d-inline-flex align-items-center">
              <i className="bi bi-phone-vibrate fs-1 text-primary me-3"></i>
              <div className="text-start">
                <h6 className="text-uppercase mb-1">Call Us</h6>
                <span>+91 9839034846</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg bg-white navbar-light shadow-sm py-3 py-lg-0 px-3 px-lg-0">
        <NavLink onClick={handleClicked} to="/" className="navbar-brand ms-lg-5">
          <img src="/img/logo.png" style={{ height: "70px" }} alt="Logo" />
        </NavLink>
        <button className="navbar-toggler" ref={toggleRef} type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-0">
            <NavLink
              onClick={handleClicked}
              to="/"
              className={({ isActive }) => `nav-item nav-link ${isActive ? 'active' : ''}`}
            >
              Home
            </NavLink>
            <NavLink
              onClick={handleClicked}
              to="/about"
              className={({ isActive }) => `nav-item nav-link ${isActive ? 'active' : ''}`}
            >
              About
            </NavLink>
            <NavLink
              onClick={handleClicked}
              to="/products"
              className={({ isActive }) => `nav-item nav-link ${isActive ? 'active' : ''}`}
            >
              Product
            </NavLink>
            <NavLink
              onClick={handleClicked}
              to="/contact"
              className={({ isActive }) => `nav-item nav-link nav-contact bg-primary text-white px-5 ms-lg-5 ${isActive ? 'active' : ''}`}
            >
              Contact <i className="bi bi-arrow-right"></i>
            </NavLink>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
