import React from 'react'

function ServiceComponent() {
  return (
    <div className="container-fluid py-5">
  <div className="container">
    <div className="border-start border-5 border-primary ps-5 mb-5" style={{maxWidth: "600px"}}>
      <h6 className="text-primary text-uppercase">Mehra Group</h6>
      <h1 className="display-5 text-uppercase mb-0">MEHRA INTERNATIONAL AT A GLANCE</h1>
    </div>
    <div className="row g-5">
      <div className="col-md-4">
        <div className="service-item bg-light p-4">
          <img src="img/fabric-sewing-machinery.jpg" className="img-fluid" />
          <h5 className="mt-3 text-center">Fabric Sewing Mechinery</h5>
        </div>
      </div>
      <div className="col-md-4">
        <div className="service-item bg-light p-4">
          <img src="img/cad-machine-used-in-pattern-making-and-designing.jpg" className="img-fluid" />
          <h5 className="mt-3 text-center">CAD Machine for Pattern Making</h5>
        </div>
      </div>
      <div className="col-md-4">
        <div className="service-item bg-light p-4">
          <img src="img/finishing-department.jpg" className="img-fluid" />
          <h5 className="mt-3 text-center">Finishing Department</h5>
        </div>
      </div>
      <div className="col-md-4">
        <div className="service-item bg-light p-4">
          <img src="img/lasting-line.jpg" className="img-fluid" />
          <h5 className="mt-3 text-center">Lasting Line</h5>
        </div>
      </div>
      <div className="col-md-4">
        <div className="service-item bg-light p-4">
          <img src="img/leather-cutting-machine.jpg" className="img-fluid" />
          <h5 className="mt-3 text-center">Leather Cutting Machine</h5>
        </div>
      </div>
      <div className="col-md-4">
        <div className="service-item bg-light p-4">
          <img src="img/leather-stitching-machinery.jpg" className="img-fluid" />
          <h5 className="mt-3 text-center">Leather Stitching Machinery</h5>
        </div>
      </div>
      <div className="col-md-4">
        <div className="service-item bg-light p-4">
          <img src="img/nailing-machine.jpg" className="img-fluid" />
          <h5 className="mt-3 text-center">Nailing Machine</h5>
        </div>
      </div>
      <div className="col-md-4">
        <div className="service-item bg-light p-4">
          <img src="img/garment-pressing.jpg" className="img-fluid" />
          <h5 className="mt-3 text-center">Garment Pressing</h5>
        </div>
      </div>
    </div>
  </div>
</div>
  )
}

export default ServiceComponent