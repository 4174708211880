// Products.js
import React from 'react'; 
import CategoryItem from './CategoryItem';




const Products = (props) => {
  return (
    <div className="container-fluid py-5">
      <div className="container">
        <div className="border-start border-5 border-primary ps-5 mb-5" style={{ maxWidth: '600px' }}>
          <h6 className="text-primary text-uppercase">Products</h6>
          <h1 className="display-5 text-uppercase mb-0">Products For Your Best Friends</h1>
        </div>
    
        <div id="owl-ul">
          <ul>
           {props.products.map((product, index) => (
            <li>
            <CategoryItem
              key={index}
              imgSrc={product.imgSrc}
              title={product.title}
              link={product.link}
            /></li>
          ))} 
          </ul>
          
        </div>
      </div>
    </div>
  );
};

export default Products;
