// ContactUs.js
import React, { useState } from 'react';
import { Mailer } from 'react-mailer';   

const ContactComponent = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await Mailer.send({
        to: 'syedsaifrza@gmail.com',
        subject: formData.subject,
        text: formData.message,
        from: formData.email,
      });

      setStatus('E-mail sent successfully!');
      setFormData({ name: '', email: '', subject: '', message: '' });
    } catch (error) {
      setStatus('E-mail sending failed. Please try again.');
    }
  };

  return (
    <div className="container-fluid pt-5">
      <div className="container">
        <div className="border-start border-5 border-primary ps-5 mb-5" style={{ maxWidth: '600px' }}>
          <h6 className="text-primary text-uppercase">Contact Us</h6>
          <h1 className="display-5 text-uppercase mb-0">Please Feel Free To Contact Us</h1>
        </div>
        <div className="row g-5">
          <div className="col-lg-7">
            <form onSubmit={handleSubmit}>
              <div className="row g-3">
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control bg-light border-0 px-4"
                    placeholder="Your Name"
                    style={{ height: '55px' }}
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-12">
                  <input
                    type="email"
                    className="form-control bg-light border-0 px-4"
                    placeholder="Your Email"
                    style={{ height: '55px' }}
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control bg-light border-0 px-4"
                    placeholder="Subject"
                    style={{ height: '55px' }}
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-12">
                  <textarea
                    className="form-control bg-light border-0 px-4 py-3"
                    rows="8"
                    placeholder="Message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <div className="col-12">
                  <button className="btn btn-primary w-100 py-3" type="submit">
                    Send Message
                  </button>
                </div>
              </div>
            </form>
            {status && <div className="alert alert-info mt-3">{status}</div>}
          </div>
          <div className="col-lg-5">
            <div className="bg-light mb-5 p-5">
              <div className="d-flex align-items-center mb-2">
                <i className="bi bi-geo-alt fs-1 text-primary me-3"></i>
                <div className="text-start">
                  <h6 className="text-uppercase mb-1">Mehra Shoes</h6>
                  <span>123/768-76-A8 Fazalganj, Factory Area Kanpur, Uttar Pradesh, India</span>
                </div>
              </div>
              <div className="d-flex align-items-center mb-2">
                <i className="bi bi-geo-alt fs-1 text-primary me-3"></i>
                <div className="text-start">
                  <h6 className="text-uppercase mb-1">Mehra Apparels & Equine Makers</h6>
                  <span>123/772A Factory Area, Fazalganj, Kanpur, Uttar Pradesh</span>
                </div>
              </div>
              <div>
                <iframe
                  className="position-relative w-100"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28575.157265283255!2d80.27238232783367!3d26.45912434041727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399c478eeba5612f%3A0x35e1a814af7c91bb!2sFazalganj%20Industrial%20Estate%2C%20Fazalganj%2C%20Shastri%20Nagar%2C%20Kanpur%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1653982142814!5m2!1sen!2sin"
                  frameBorder="0"
                  style={{ height: '205px', border: '0' }}
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactComponent;
