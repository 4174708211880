import React from 'react'

function AboutComponent() {
  return (
    <div className="container-fluid py-5">
  <div className="container">
    <div className="row gx-5">
      <div className="col-lg-5 mb-5 mb-lg-0" style={{minHeight: "500px"}}>
        <div className="position-relative h-100">
          <img className="position-absolute w-100 h-100 rounded" src="img/about1.jpg" style={{objectFit: "cover"}} />
        </div>
      </div>
      <div className="col-lg-7">
        <div className="border-start border-5 border-primary ps-5 mb-5">
          <h1 className="text-primary text-uppercase">About Us</h1>
          <h1 className="display-5 text-uppercase mb-0">We Keep Your Pets Happy All Time</h1>
        </div>
        <h4 className="text-body mb-4">The new generation took the responsibility of introducing new products in the
          broader world market.</h4>
        <div className="bg-light p-4">
          <ul className="nav nav-pills justify-content-between mb-3" id="pills-tab" role="tablist">
            <li className="nav-item w-50" role="presentation">
              <button className="nav-link text-uppercase w-100 active" id="pills-1-tab" data-bs-toggle="pill"
                data-bs-target="#pills-1" type="button" role="tab" aria-controls="pills-1" aria-selected="true">MISSION
                AND VISION</button>
            </li>
            <li className="nav-item w-50" role="presentation">
              <button className="nav-link text-uppercase w-100" id="pills-2-tab" data-bs-toggle="pill"
                data-bs-target="#pills-2" type="button" role="tab" aria-controls="pills-2"
                aria-selected="false">ACHIEVEMENTS</button>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-1" role="tabpanel" aria-labelledby="pills-1-tab">
              <p className="mb-0">Our company is an export oriented company with most of its sales being contributed to 12
                countries including UK, Germany, Italy, Sweden, Denmark, Ireland etc. We aim to enhance customer
                satisfaction by sustaining to superior
                quality and our mission covers the entire globe.</p>
            </div>
            <div className="tab-pane fade" id="pills-2" role="tabpanel" aria-labelledby="pills-2-tab">
              <p className="mb-0">Mehra shoes has already turned out a leading entrant by earning emerging footwear export
                of the year award by export promotion council India in the year 2011. Above this is the trust shown in
                our products by the famous
                equestrian companies around the world.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  )
}

export default AboutComponent