// Products.js
import React, { useState, useEffect } from 'react';
import ProductItem from '../components/ProductItem';
import { useParams } from 'react-router-dom';

// Ensure this component is correctly implemented

const SingleCategoryPage = () => {
  const [products, setProducts] = useState([]);
  const { category } = useParams();
  const productList=[
    {"pid":"6","category":"Bridle","dscn":"2308","leatherDetails":" A QUALITY INDIAN DRUM DYED LEATHER ","description":"Snaffle Bridle W\/ Multi Color Crystal on B.B & Raised & Padded Noseband W\/Chin Pad W\/Web Reins","image1":"IMG_2308.JPG","image2":"IMG_2308 - Copy.JPG","image3":""},
    {"pid":"7","category":"Bridle","dscn":"2307","leatherDetails":" Italian Leather ","description":"Snaffle Bridle W\/ Fancy Stitching on B.B & Noseband W\/WEB REINS ","image1":"IMG_2307 - Copy.JPG","image2":"IMG_2307.JPG","image3":""},
    {"pid":"8","category":"Bridle","dscn":"2271","leatherDetails":"Italian Leather","description":"Mexican Bridle W\/Crystal On B.B & Noseband W\/WEB REINS ","image1":"Picture1.jpg","image2":"Picture2.jpg","image3":""},
    {"pid":"9","category":"Bridle","dscn":"2306","leatherDetails":"PRIEMUM QUALITY INDIAN DRUM DYED LEATHER ","description":"SNB 5\/8\" Plain Raised B.B, 6\/8\" Plain Raised JNB, 7\/8\" Flat Crown W\/WEB REINS","image1":"Picture4.jpg","image2":"Picture3.jpg","image3":""},
    {"pid":"10","category":"Bridle","dscn":"2282","leatherDetails":"SUPER TAN LEATHER","description":"SNB 5\/8\" Heavy Flat padded B.B, 7\/8\" Flat Heavy padded JNB, 8\/8\" Heavy padded Crown W\/WEB REINS","image1":"Picture6.jpg","image2":"Picture5.jpg","image3":""},
    {"pid":"11","category":"Bridle","dscn":"2286","leatherDetails":"SUPER TAN LEATHER ","description":"Dressage Bridle. 5\/8\" Diamond and padded B.B, 8\/8\"Raised and Fancy Dressage Noseband W\/WEB REINS","image1":"Picture8.jpg","image2":"Picture7.jpg","image3":""},
    {"pid":"14","category":"Bridle","dscn":"2278","leatherDetails":"SUPER TAN LEATHER ","description":"Dressage Bridle Diamond and padded B.B & JNB,W\/WEB REINS","image1":"Picture11.jpg","image2":"Picture10.jpg","image3":""},
    {"pid":"15","category":"Bridle","dscn":"2312","leatherDetails":"SUPER TAN LEATHER ","description":"SNB Small Clinchered B.B,  Clinchered JNB, W\/WEB REINS","image1":"Picture12.jpg","image2":"Picture13.jpg","image3":""},
    {"pid":"16","category":"Bridle","dscn":"2269","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Bridle W\/ Fancy Stitched on B.B, Shaped Noseband W\/WEB REINS","image1":"Picture14.jpg","image2":"Picture15.jpg","image3":""},
    {"pid":"17","category":"Bridle","dscn":"2270","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"SNB W\/ Crystals on Center of B.B and Raised on side of B.B,  Raised Noseband W\/ Chin Pad W\/WEB REINS","image1":"Picture16.jpg","image2":"Picture17.jpg","image3":""},
    {"pid":"18","category":"Bridle","dscn":"2273","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Dressage Bridle W\/ Crystals on B.B, Drop Shaped Noseband Golden Foil Leather Padding W\/WEB REINS","image1":"Picture18.jpg","image2":"Picture19.jpg","image3":""},
    {"pid":"19","category":"Bridle","dscn":"2277","leatherDetails":"Argentina Leather","description":"Bridle W\/ Fancy Stitched on B.B& Noseband W\/WEB REINS","image1":"Picture20.jpg","image2":"Picture21.jpg","image3":""},
    {"pid":"20","category":"Bridle","dscn":"2279","leatherDetails":"Super Tan Leather ","description":"SNB W\/ Crystal in center of B.B and Breaded on both sides, Breaded  Noseband W\/WEB REINS","image1":"Picture22.jpg","image2":"Picture23.jpg","image3":""},
    {"pid":"21","category":"Bridle","dscn":"2290","leatherDetails":"Argentina Print Leather ","description":"SNB W\/  Crystal on B.B, JNB W\/WEB REINS","image1":"Picture24.jpg","image2":"Picture25.jpg","image3":""},
    {"pid":"22","category":"Bridle","dscn":"2293","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"SNB W\/ Beads Crystal B.B, Raised & padded Noseband W\/WEB REINS","image1":"Picture26.jpg","image2":"Picture27.jpg","image3":""},
    {"pid":"23","category":"Bridle","dscn":" 2294","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"SNB W\/ Blue Crystal on B.B,  JNB W\/WEB REINS\u000b","image1":"Picture28.jpg","image2":"Picture29.jpg","image3":""},
    {"pid":"24","category":"Bridle","dscn":"2297","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Dressage Bridle W\/ White Crystal on B.B, W\/WEB REINS","image1":"Picture30.jpg","image2":"Picture31.jpg","image3":""},
    {"pid":"25","category":"Bridle","dscn":"2299","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"SNB W\/ Crystal on B.B, Square Raised on Noseband W\/WEB REINS","image1":"Picture32.jpg","image2":"Picture33.jpg","image3":""},
    {"pid":"26","category":"Bridle","dscn":"2301","leatherDetails":"Argentina Leather ","description":"SNB W\/ Crystal on B.B, Raised Noseband W\/WEB REINS","image1":"Picture34.jpg","image2":"Picture35.jpg","image3":""},
    {"pid":"27","category":"Bridle","dscn":"2302","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Mexican Bridle W\/ Fancy Stitching On B.B & Noseband W\/WEB REINS ","image1":"Picture36.jpg","image2":"Picture37.jpg","image3":""},
    {"pid":"28","category":"Halter","dscn":"7662","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER","description":"SUPER GRIP HALTER WITH LEATHER","image1":"Picture38.jpg","image2":"","image3":""},
    {"pid":"29","category":"Halter","dscn":"7668","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER","description":"FANCY STITCHING HALTER WITH 2 SNAPS","image1":"Picture39.jpg","image2":"","image3":""},
    {"pid":"30","category":"Halter","dscn":"2244","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER","description":"FANCY STITCHING HALTER WITH 2 SNAPS","image1":"Picture40.jpg","image2":"Picture41.jpg","image3":""},
    {"pid":"31","category":"Halter","dscn":"2254","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER","description":"FANCY STITCHING HALTER WITH 2 SNAPS","image1":"Picture42.jpg","image2":"","image3":""},
    {"pid":"32","category":"Reins","dscn":"2230","leatherDetails":"SUPER TAN LEATHER ","description":"Rubber Grip Reins 5\/8\" with Buckles ","image1":"Picture43.jpg","image2":"","image3":""},
    {"pid":"33","category":"Reins","dscn":"2231","leatherDetails":"SUPER TAN LEATHER ","description":"Rubber Grip Reins 4\/8\" with Buckles ","image1":"Picture44.jpg","image2":"","image3":""},
    {"pid":"34","category":"Reins","dscn":"2232","leatherDetails":"SUPER TAN LEATHER ","description":"Hunter Reins 5\/8\" with Buckles ","image1":"Picture45.jpg","image2":"","image3":""},
    {"pid":"35","category":"Reins","dscn":"0626","leatherDetails":"SUPER TAN LEATHER ","description":"Web Reins 6\/8\" with Buckles ","image1":"Picture46.jpg","image2":"","image3":""},
    {"pid":"36","category":"Reins","dscn":"2226","leatherDetails":"SUPER TAN LEATHER ","description":"Super Grip Reins W\/ Colored Loops ","image1":"Picture47.jpg","image2":"","image3":""},
    {"pid":"37","category":"Breast Plate","dscn":"0605","leatherDetails":"PREMIUM QUALITY INDIAN DRUM DYED LEATHER","description":"Clinchered Breast plate with Running Attachment. \u000b","image1":"Picture48.jpg","image2":"","image3":""},
    {"pid":"38","category":"Breast Plate","dscn":"0608","leatherDetails":"SUPER TAN LEATHER ","description":"Elastic Breastplate \"Y\" Shape with Running Attachment. ","image1":"Picture49.jpg","image2":"","image3":""},
    {"pid":"39","category":"Breast Plate","dscn":"0946","leatherDetails":"SUPER TAN LEATHER ","description":"PHELAM ROUNDING W\/BUCKLE \u000b","image1":"Picture50.jpg","image2":"","image3":""},
    {"pid":"40","category":"Breast Plate","dscn":"2224","leatherDetails":"SOFT COW LEATHER ","description":"Leather covered Nylon stirrup leather 1\" \u000b","image1":"Picture51.jpg","image2":"","image3":""},
    {"pid":"41","category":"Girth","dscn":"2222","leatherDetails":"SUPER TAN LEATHER ","description":"Saddle Girth both Elastic end. ","image1":"Picture52.jpg","image2":"","image3":""},
    {"pid":"42","category":"Girth","dscn":"2219","leatherDetails":"SUPER TAN LEATHER ","description":"Atherstone Girth Both Elastic End. ","image1":"Picture53.jpg","image2":"","image3":""},
    {"pid":"43","category":"Girth","dscn":"0639","leatherDetails":"PREMIUM QUALITY INDIAN DRUM DYED LEATHER ","description":"Leather Stud Guard Girth Both Elastic End. ","image1":"Picture54.jpg","image2":"","image3":""},
    {"pid":"44","category":"Browband","dscn":"2197","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"U SHAPE BROWBAND W\/CRYSTAL ","image1":"Picture55.jpg","image2":"","image3":""},
    {"pid":"45","category":"Browband","dscn":"2198","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"STRAIGHT BROWBAND W\/ZIP RAINBOW CRYSTAL ","image1":"Picture56.jpg","image2":"","image3":""},
    {"pid":"46","category":"Browband","dscn":"2199","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"STRAIGHT BROWBAND W\/ BLACK COLOR CRYSTAL ","image1":"Picture57.jpg","image2":"","image3":""},
    {"pid":"47","category":"Browband","dscn":"2200","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"STRAIGHT BROWBAND W\/ CRYSTAL \u000b","image1":"Picture58.jpg","image2":"","image3":""},
    {"pid":"48","category":"Browband","dscn":"2203","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"STRAIGHT SHAPE BROWBAND W\/ CRYSTAL","image1":"Picture59.jpg","image2":"","image3":""},
    {"pid":"49","category":"Browband","dscn":"2205","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"V SHAPE BROWBAND W\/ BEADS ","image1":"Picture60.jpg","image2":"","image3":""},
    {"pid":"50","category":"Browband","dscn":"2208","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"STRAIGHT SHAPE BROWBAND W\/CRYSTAL ","image1":"Picture61.jpg","image2":"","image3":""},
    {"pid":"51","category":"Browband","dscn":"2210","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"U SHAPE BROWBAND W\/CRYSTAL ","image1":"Picture62.jpg","image2":"","image3":""},
    {"pid":"52","category":"Browband","dscn":"2212","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"U SHAPE BROWBAND W\/ WHITE CRYSTAL ","image1":"Picture63.jpg","image2":"","image3":""},
    {"pid":"53","category":"Browband","dscn":"2214","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"U SHAPE BROWBAND W\/ CRYSTAL","image1":"Picture64.jpg","image2":"","image3":""},
    {"pid":"54","category":"Browband","dscn":"2215","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"STRAIGHT BROWBAND W\/ WHITE CRYSTAL ","image1":"Picture65.jpg","image2":"","image3":""},
    {"pid":"55","category":"Belt","dscn":"2132","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Leather Belt W\/Green Rock Crystal 1\" ","image1":"Picture66.jpg","image2":"Picture67.jpg","image3":""},
    {"pid":"56","category":"Belt","dscn":"2134","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Leather Belt W\/Round Crystal 6\/8\" ","image1":"Picture68.jpg","image2":"Picture69.jpg","image3":""},
    {"pid":"57","category":"Belt","dscn":"2145","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Leather Belt W\/ Colored Synthetic Material\/Square Crystal 1\" ","image1":"Picture70.jpg","image2":"Picture71.jpg","image3":""},
    {"pid":"58","category":"Belt","dscn":"2148","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Leather  Belt 8\/8\" W\/ Emb ","image1":"Picture72.jpg","image2":"Picture73.jpg","image3":""},
    {"pid":"59","category":"Belt","dscn":"2150","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Leather Belt W\/White Crystal  1\" ","image1":"Picture74.jpg","image2":"Picture75.jpg","image3":""},
    {"pid":"60","category":"Belt","dscn":"2152","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Braided Belt W\/Rose Gold Crystal 1\" \u000b","image1":"Picture76.jpg","image2":"Picture77.jpg","image3":""},
    {"pid":"61","category":"Belt","dscn":"2154","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Leather Belt 8\/8\" W\/ 2 line Beads ","image1":"Picture78.jpg","image2":"Picture79.jpg","image3":""},
    {"pid":"62","category":"Belt","dscn":"2156","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Leather Belt W\/ BREADED  ","image1":"Picture80.jpg","image2":"Picture81.jpg","image3":""},
    {"pid":"63","category":"Belt","dscn":"2158","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Leather Belt 8\/8\" W\/Beads ","image1":"Picture82.jpg","image2":"Picture83.jpg","image3":""},
    {"pid":"64","category":"Belt","dscn":"2163","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Leather Belt 8\/8\" W\/ Sequins ","image1":"Picture84.jpg","image2":"Picture85.jpg","image3":""},
    {"pid":"65","category":"Belt","dscn":"2165","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER","description":"Leather Belt W\/Silver Synthetic Material W\/Black Crystal 1\" \u000b","image1":"Picture86.jpg","image2":"Picture87.jpg","image3":""},
    {"pid":"66","category":"Belt","dscn":"2167","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Leather Belt W\/Rock Crystal 1\" ","image1":"Picture88.jpg","image2":"Picture89.jpg","image3":""},
    {"pid":"67","category":"Belt","dscn":"2169","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Leather Belt 8\/8\" W\/Beads ","image1":"Picture90.jpg","image2":"Picture91.jpg","image3":""},
    {"pid":"68","category":"Belt","dscn":"2171","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Leather Belt W\/Blue Crystal 1\" ","image1":"Picture92.jpg","image2":"Picture93.jpg","image3":""},
    {"pid":"69","category":"Belt","dscn":"2174","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Leather Belt 8\/8\" W\/ Emb ","image1":"Picture95.jpg","image2":"Picture94.jpg","image3":""},
    {"pid":"70","category":"Belt","dscn":"2176","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Leather Belt W\/Greenish Crystal 1\" ","image1":"Picture96.jpg","image2":"Picture97.jpg","image3":""},
    {"pid":"71","category":"Dog Collar","dscn":"2191","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Dog Collar W\/ Emb Burgundy ","image1":"Picture98.jpg","image2":"Picture99.jpg","image3":""},
    {"pid":"72","category":"Dog Collar","dscn":"2184","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Dog Collar W\/ Beads ","image1":"Picture100.jpg","image2":"Picture101.jpg","image3":""},
    {"pid":"73","category":"Dog Collar","dscn":"2179","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER \u000b","description":"Dog Collar W\/ Emb Grey & Pink ","image1":"Picture102.jpg","image2":"","image3":""},
    {"pid":"74","category":"Dog Collar","dscn":"2181","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Dog Collar W\/ Beads ","image1":"Picture103.jpg","image2":"Picture104.jpg","image3":""},
    {"pid":"75","category":"Dog Collar","dscn":"2189","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Dog Collar W\/ Emb Blue ","image1":"Picture105.jpg","image2":"Picture106.jpg","image3":""},
    {"pid":"76","category":"Dog Collar","dscn":"2186","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Dog Collar W\/ Emb Orange ","image1":"Picture107.jpg","image2":"Picture108.jpg","image3":""},
    {"pid":"77","category":"Dog Collar","dscn":"2193","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER \u000b","description":"Dog Collar W\/ BREADING ","image1":"Picture109.jpg","image2":"Picture110.jpg","image3":""},
    {"pid":"78","category":"Western Saddle","dscn":"2314","leatherDetails":"Synthetic Material ","description":"Western Synthetic Saddle W\/ Snake Print","image1":"Picture111.jpg","image2":"Picture112.jpg","image3":""},
    {"pid":"79","category":"Western Saddle","dscn":"2319","leatherDetails":"Synthetic Material ","description":"Western Synthetic Saddle W\/Polka Dots","image1":"Picture113.jpg","image2":"Picture114.jpg","image3":""},
    {"pid":"80","category":"Western Saddle","dscn":"2323","leatherDetails":"Synthetic Material ","description":"Western Synthetic Saddle W\/ Check Pattern \u000b","image1":"Picture115.jpg","image2":"Picture116.jpg","image3":""},
    {"pid":"81","category":"Western Saddle","dscn":"2328","leatherDetails":"Synthetic Material ","description":"Western Synthetic Saddle ","image1":"Picture117.jpg","image2":"Picture118.jpg","image3":""},
    {"pid":"82","category":"Western Saddle","dscn":"2333","leatherDetails":"Synthetic Material ","description":"Western Synthetic Saddle W\/ Leopard Print ","image1":"Picture119.jpg","image2":"Picture120.jpg","image3":""},
    {"pid":"83","category":"Saddle","dscn":"0642","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Dressage Saddle With Wool Panel in Plastic Tree ","image1":"Picture121.jpg","image2":"","image3":""},
    {"pid":"84","category":"Saddle","dscn":"0683","leatherDetails":"A QUALITY INDIAN DRUM DYED LEATHER ","description":"Jumping Saddle with foam panel  ","image1":"Picture122.jpg","image2":"","image3":""},
    {"pid":"86","category":"Belt","dscn":"","leatherDetails":"","description":"","image1":"IMG_2135.JPG","image2":"IMG_2136.JPG","image3":""},
    {"pid":"87","category":"Belt","dscn":"","leatherDetails":"","description":"","image1":"IMG_2140.JPG","image2":"IMG_2141.JPG","image3":""},
    {"pid":"88","category":"Belt","dscn":"","leatherDetails":"","description":"","image1":"IMG_2159.JPG","image2":"IMG_2161.JPG","image3":""},
    {"pid":"89","category":"Reins","dscn":"","leatherDetails":"","description":"","image1":"IMG_2224.JPG","image2":"","image3":""},
    {"pid":"90","category":"Breast Plate","dscn":"","leatherDetails":"","description":"","image1":"IMG_2227.JPG","image2":"","image3":""},
    {"pid":"91","category":"Western Saddle","dscn":"","leatherDetails":"","description":"","image1":"IMG_2338.JPG","image2":"IMG_2341.JPG","image3":""},
    {"pid":"92","category":"Western Saddle","dscn":"","leatherDetails":"","description":"","image1":"IMG_2343.JPG","image2":"IMG_2346.JPG","image3":""},
    {"pid":"93","category":"Western Saddle","dscn":"","leatherDetails":"","description":"","image1":"IMG_2348.JPG","image2":"IMG_2350.JPG","image3":""},
    {"pid":"94","category":"Western Saddle","dscn":"","leatherDetails":"","description":"","image1":"IMG_2358.JPG","image2":"","image3":""},
    {"pid":"95","category":"Western Saddle","dscn":"","leatherDetails":"","description":"","image1":"IMG_2361.JPG","image2":"","image3":""},
    {"pid":"96","category":"Halter","dscn":"","leatherDetails":"","description":"","image1":"IMG_2240.JPG","image2":"IMG_2240 - Copy.JPG","image3":""},
    {"pid":"97","category":"Halter","dscn":"","leatherDetails":"","description":"","image1":"IMG_2242.JPG","image2":"IMG_2242 - Copy.JPG","image3":""},
    {"pid":"98","category":"Halter","dscn":"","leatherDetails":"","description":"","image1":"IMG_2244.JPG","image2":"IMG_2245.JPG","image3":""},
    {"pid":"99","category":"Halter","dscn":"","leatherDetails":"","description":"","image1":"IMG_2246.JPG","image2":"IMG_2246 - Copy.JPG","image3":""},
    {"pid":"100","category":"Halter","dscn":"","leatherDetails":"","description":"","image1":"IMG_2248.JPG","image2":"IMG_2248 - Copy.JPG","image3":""},
    {"pid":"101","category":"Halter","dscn":"","leatherDetails":"","description":"","image1":"IMG_2249.JPG","image2":"IMG_2249 - Copy.JPG","image3":""},
    {"pid":"102","category":"Halter","dscn":"","leatherDetails":"","description":"","image1":"IMG_2250.JPG","image2":"IMG_2250 - Copy.JPG","image3":""},
    {"pid":"103","category":"Halter","dscn":"","leatherDetails":"","description":"","image1":"IMG_2252.JPG","image2":"IMG_2252 - Copy.JPG","image3":""},
    {"pid":"104","category":"Halter","dscn":"","leatherDetails":"","description":"","image1":"IMG_2260.JPG","image2":"IMG_2260 - Copy.JPG","image3":""},
    {"pid":"105","category":"Halter","dscn":"","leatherDetails":"","description":"","image1":"IMG_2261.JPG","image2":"IMG_2261 - Copy.JPG","image3":""},
    {"pid":"106","category":"Breeches","dscn":"","leatherDetails":"","description":"","image1":"IMG_2365.JPG","image2":"IMG_2367.JPG","image3":"IMG_2368.JPG"},
    {"pid":"107","category":"Breeches","dscn":"","leatherDetails":"","description":"","image1":"IMG_2381.JPG","image2":"IMG_2382.JPG","image3":"IMG_2383.JPG"},
    {"pid":"108","category":"Breeches","dscn":"","leatherDetails":"","description":"","image1":"IMG_2384.JPG","image2":"IMG_2385.JPG","image3":"IMG_2386.JPG"},
    {"pid":"109","category":"Breeches","dscn":"","leatherDetails":"","description":"","image1":"IMG_2387.JPG","image2":"IMG_2388.JPG","image3":"IMG_2389.JPG"},
    {"pid":"110","category":"Breeches","dscn":"","leatherDetails":"","description":"","image1":"IMG_2390.JPG","image2":"IMG_2391.JPG","image3":"IMG_2392.JPG"},
    {"pid":"111","category":"Breeches","dscn":"","leatherDetails":"","description":"","image1":"IMG_2393.JPG","image2":"IMG_2395.JPG","image3":"IMG_2396.JPG"},
    {"pid":"112","category":"Breeches","dscn":"","leatherDetails":"","description":"","image1":"IMG_2397.JPG","image2":"IMG_2398.JPG","image3":"IMG_2399.JPG"},
    {"pid":"113","category":"Breeches","dscn":"","leatherDetails":"","description":"","image1":"IMG_2405.JPG","image2":"IMG_2406.JPG","image3":"IMG_2407.JPG"},
    {"pid":"114","category":"Breeches","dscn":"","leatherDetails":"","description":"","image1":"IMG_2409.JPG","image2":"IMG_2410.JPG","image3":"IMG_2411.JPG"},
    {"pid":"115","category":"Breeches","dscn":"","leatherDetails":"","description":"","image1":"IMG_2412.JPG","image2":"IMG_2413.JPG","image3":"IMG_2414.JPG"},
    {"pid":"116","category":"Breeches","dscn":"","leatherDetails":"","description":"","image1":"IMG_2415.JPG","image2":"IMG_2416.JPG","image3":"IMG_2418.JPG"},
    {"pid":"117","category":"Breeches","dscn":"","leatherDetails":"","description":"","image1":"IMG_2421.JPG","image2":"IMG_2423.JPG","image3":"IMG_2424.JPG"},
    {"pid":"118","category":"Breeches","dscn":"","leatherDetails":"","description":"","image1":"IMG_2425.JPG","image2":"IMG_2426.JPG","image3":"IMG_2427.JPG"},
    {"pid":"119","category":"Breeches","dscn":"","leatherDetails":"","description":"","image1":"IMG_2429.JPG","image2":"IMG_2430.JPG","image3":"IMG_2431.JPG"},
    {"pid":"120","category":"Breeches","dscn":"","leatherDetails":"","description":"","image1":"IMG_2433.JPG","image2":"IMG_2434.JPG","image3":"IMG_2435.JPG"},
    {"pid":"121","category":"Breeches","dscn":"","leatherDetails":"","description":"","image1":"IMG_2444.JPG","image2":"IMG_2445.JPG","image3":"IMG_2446.JPG"},
    {"pid":"122","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1759.JPG","image2":"IMG_1760.JPG","image3":"IMG_1761.JPG"},
    {"pid":"123","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1762.JPG","image2":"IMG_1763.JPG","image3":"IMG_1764.JPG"},
    {"pid":"124","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1766.JPG","image2":"IMG_1767.JPG","image3":"IMG_1768.JPG"},
    {"pid":"125","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1769.JPG","image2":"IMG_1770.JPG","image3":"IMG_1773.JPG"},
    {"pid":"126","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1774.JPG","image2":"IMG_1775.JPG","image3":"IMG_1776.JPG"},
    {"pid":"127","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1777.JPG","image2":"IMG_1778.JPG","image3":"IMG_1779.JPG"},
    {"pid":"128","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1780.JPG","image2":"IMG_1781.JPG","image3":"IMG_1782.JPG"},
    {"pid":"129","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_2093.JPG","image2":"IMG_2094.JPG","image3":"IMG_2095.JPG"},
    {"pid":"130","category":"Breeches","dscn":"","leatherDetails":"","description":"","image1":"IMG_2444.JPG","image2":"IMG_2445.JPG","image3":"IMG_2446.JPG"},
    {"pid":"131","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1787.JPG","image2":"IMG_1788.JPG","image3":"IMG_1789.JPG"},
    {"pid":"132","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1790.JPG","image2":"IMG_1791.JPG","image3":"IMG_1792.JPG"},
    {"pid":"133","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1793.JPG","image2":"IMG_1794.JPG","image3":"IMG_1795.JPG"},
    {"pid":"134","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1796.JPG","image2":"IMG_1797.JPG","image3":"IMG_1798.JPG"},
    {"pid":"135","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1799.JPG","image2":"IMG_1800.JPG","image3":"IMG_1801.JPG"},
    {"pid":"136","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1802.JPG","image2":"IMG_1803.JPG","image3":"IMG_1804.JPG"},
    {"pid":"137","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1805.JPG","image2":"IMG_1806.JPG","image3":"IMG_1807.JPG"},
    {"pid":"138","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1809.JPG","image2":"IMG_1810.JPG","image3":"IMG_1811.JPG"},
    {"pid":"139","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1812.JPG","image2":"IMG_1813.JPG","image3":"IMG_1814.JPG"},
    {"pid":"140","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1818.JPG","image2":"IMG_1819.JPG","image3":"IMG_1820.JPG"},
    {"pid":"141","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1821.JPG","image2":"IMG_1822.JPG","image3":"IMG_1823.JPG"},
    {"pid":"142","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1824.JPG","image2":"IMG_1825.JPG","image3":"IMG_1826.JPG"},
    {"pid":"143","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1827.JPG","image2":"IMG_1828.JPG","image3":"IMG_1829.JPG"},
    {"pid":"144","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1831.JPG","image2":"IMG_1832.JPG","image3":"IMG_1833.JPG"},
    {"pid":"145","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1834.JPG","image2":"IMG_1835.JPG","image3":"IMG_1836.JPG"},
    {"pid":"146","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1837.JPG","image2":"IMG_1838.JPG","image3":"IMG_1839.JPG"},
    {"pid":"147","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1840.JPG","image2":"IMG_1843.JPG","image3":"IMG_1844.JPG"},
    {"pid":"148","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1847.JPG","image2":"IMG_1848.JPG","image3":"IMG_1849.JPG"},
    {"pid":"149","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_2097.JPG","image2":"IMG_2098.JPG","image3":"IMG_2100.JPG"},
    {"pid":"150","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1854.JPG","image2":"IMG_1855.JPG","image3":"IMG_1856.JPG"},
    {"pid":"151","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1857.JPG","image2":"IMG_1858.JPG","image3":"IMG_1859.JPG"},
    {"pid":"152","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1860.JPG","image2":"IMG_1861.JPG","image3":"IMG_1862.JPG"},
    {"pid":"153","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1864.JPG","image2":"IMG_1865.JPG","image3":"IMG_1866.JPG"},
    {"pid":"154","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1867.JPG","image2":"IMG_1868.JPG","image3":"IMG_1869.JPG"},
    {"pid":"155","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1870.JPG","image2":"IMG_1871.JPG","image3":"IMG_1872.JPG"},
    {"pid":"156","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1876.JPG","image2":"IMG_1877.JPG","image3":"IMG_1878.JPG"},
    {"pid":"157","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1879.JPG","image2":"IMG_1880.JPG","image3":"IMG_1881.JPG"},
    {"pid":"158","category":"Shoes","dscn":"","leatherDetails":"","description":"","image1":"IMG_1882.JPG","image2":"IMG_1883.JPG","image3":"IMG_1884.JPG"},
    {"pid":"159","category":"Breeches","dscn":"","leatherDetails":"","description":"","image1":"IMG_2610.JPG","image2":"IMG_2611.JPG","image3":"IMG_2612.JPG"},
    {"pid":"160","category":"Breeches","dscn":"","leatherDetails":"","description":"","image1":"IMG_2606.JPG","image2":"IMG_2607.JPG","image3":"IMG_2608.JPG"},
    {"pid":"161","category":"Bridle","dscn":"aadada","leatherDetails":"ahaha","description":"sjsjsj","image1":"ninja.jpg","image2":"mini5kb.php","image3":"yek.php"}
    
    
    ];
    
  useEffect(() => { 
    // Fetch data from the JSON file
    

        // Filter products based on the category
        const filteredProducts = productList.filter(product => product.category === category);
        
        setProducts(filteredProducts);
       
  }, [category]);

  return (
    <div className="container-fluid py-5">
      <div className="container">
        <div className="border-start border-5 border-primary ps-5 mb-5" style={{ maxWidth: '600px' }}>
          <h6 className="text-primary text-uppercase">Products</h6>
          <h1 className="display-5 text-uppercase mb-0">{category}</h1>
        </div>

        <div className="row">
          {products.length > 0 ? (
            products.map((product) => (
              <div className="col-sm-3 pb-5" key={product.id}>
                <ProductItem
                  imgSrc={`https://mehragroup.co.in/admin-data/${product.image1}`}
                  title={product.title}
                  link={`https://mehragroup.co.in/admin-data/${product.image1}`}
                  dscn={product.dscn}
                  leatherDetails={product.leatherDetails}
                  description={product.description}
                />
              </div>
            ))
          ) : (
            <center><h2 style={{ color: 'red' }}>Product Not Found</h2></center>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleCategoryPage;
