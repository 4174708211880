import React from 'react' 
import AboutComponent from '../components/AboutComponent'
import ServiceComponent from '../components/ServiceComponent'
import Products from '../components/Products'

function Home() {
  const products = [
    { imgSrc: 'img/bridle.jpg', title: 'Bridle', link: 'product-view/Bridle' },
    { imgSrc: 'img/halter.jpg', title: 'Halter', link: 'product-view/Halter' },
    { imgSrc: 'img/reins.jpg', title: 'Reins', link: 'product-view/Reins' },
    { imgSrc: 'img/belt.jpg', title: 'Belt', link: 'product-view/Belt' },
    
  ];
  return (
    
    <>
    <AboutComponent/>
    <ServiceComponent/>
    <Products products={products} />
    </>
  )
}

export default Home