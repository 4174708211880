import React from 'react'
import Products from '../components/Products';

function ProductPage() {
  const products = [
    { imgSrc: 'img/bridle.jpg', title: 'Bridle', link: 'product-view/Bridle' },
    { imgSrc: 'img/halter.jpg', title: 'Halter', link: 'product-view/Halter' },
    { imgSrc: 'img/reins.jpg', title: 'Reins', link: 'product-view/Reins' },
    { imgSrc: 'img/belt.jpg', title: 'Belt', link: 'product-view/Belt' },
    { imgSrc: 'img/breech.JPG', title: 'Breeches', link: 'product-view/Breeches' },
    { imgSrc: 'img/shoes.JPG', title: 'Shoes', link: 'product-view/Shoes' },
    { imgSrc: 'img/band.JPG', title: 'Browband', link: 'product-view/Browband' },
    { imgSrc: 'img/western.JPG', title: 'Western Saddle', link: 'product-view/Western Saddle' },
    { imgSrc: 'img/dog.JPG', title: 'Dog Collar', link: 'product-view/Dog Collar' },
    { imgSrc: 'img/belt.jpg', title: 'Belt', link: 'product-view/Belt' },
    { imgSrc: 'img/girth.JPG', title: 'Girth', link: 'product-view/Girth' },
    { imgSrc: 'img/plate.JPG', title: 'Breast Plate', link: 'product-view/Breast Plate' },
    { imgSrc: 'img/dressage saddle.jpg', title: 'Saddle', link: 'product-view/Saddle' },
  ];
  return (

   <Products products={products}/>
  )
}

export default ProductPage