// ProductItem.js
import React from 'react';

const CategoryItem = ({ imgSrc, title, link }) => {
  return (
    <div className="pb-5">
      <div className="product-item position-relative bg-light d-flex flex-column text-center">
        <img className="img-fluid mb-4" src={imgSrc} alt={title} />
        <h6 className="text-uppercase">{title}</h6>
        <div className="btn-action d-flex justify-content-center">
          <a className="btn btn-primary py-2 px-3" href={link}>
            <i className="bi bi-eye"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CategoryItem;
