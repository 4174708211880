import React from 'react'
import AboutComponent from './../components/AboutComponent';


function About() {
  return (
    <AboutComponent />
  )
}

export default About